<template>
  <v-card class="py-2 px-3">
    <div class="">
      <p class="ms-0 my-0 f-sanspro fw-600 fs-10pt" v-cHex="hexShades(cor.active.B,0.5)">
        <i class="btn-icon-left" v-i:duo#chart-line#18></i>
        UCs com ofertas (2021/2) X Itens de Questões
      </p>
    </div>
    <v-card class="py-1 px-2 mt-3" v-bg:b#4 @click="$emit('cardClick')">
      <div class="my-1 mx-0 flexb falign-center" style="justify-content: space-between;">
        <div class="">
          <p class="ms-0 my-0 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
            <i class="btn-icon-left" v-i:duo#graduation-cap#16></i>
            <span class="">UCs (com itens)</span>
            <span class="ms-3 f-sanspro fs-12pt fw-800">{{Object.keys(questoesPorUc).length}}</span>
          </p>
          <p class="ms-0 my-0 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
            <i class="btn-icon-left" v-i:duo#graduation-cap#16></i>
            <span class="">UCs (sem itens)</span>
            <span class="ms-3 f-sanspro fs-12pt fw-800">{{Object.keys(ucsSemQuestoes).length}}</span>
          </p>
          <p class="ms-0 my-0 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
            <i class="btn-icon-left" v-i:duo#target#16></i>
            <span class="">Itens Questão</span>
            <span class="ms-3 f-sanspro fs-12pt fw-800">{{qtdeQuestoes}}</span>
          </p>
        </div>
        <div class="text-center">
          <p class="ms-0 mb-2 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
            <span class="">Itens / UCs (com itens)</span>
          </p>
          <p class="ms-0 my-0 mt-n1 f-roboto fw-500 fs-13pt" v-cHex="hexShades(cor.active.B,1)">
            <span class="border px-1 rounded">{{parseFloat(qtdeQuestoes / Object.keys(questoesPorUc).length).toFixed(1)}}</span>
          </p>
        </div>
      </div>
    </v-card>
    <div class="mt-3">
      <p class="ms-0 my-0 f-sanspro fw-400 fs-9pt" v-cHex="cor.active.I">
        <span class="ms-1 absolute pt-n1" v-i:ic#circle#12 v-c:I style="left: 6px;"></span>
        <span class="ms-3 fw-600 fs-11pt">Qtde de UCs</span> contendo
        <span v-c:K class="ms-0 rounded border px-1 py-1 fs-8pt f-roboto fw-600">Itens Questões</span>
      </p>
      <v-sheet color="transparent" class="mt-n1">
        <v-sparkline :value="distribuicao" label-size="12" :color="cor.active.I" height="80" padding="14" auto-draw smooth>
          <template v-slot:label="item">
            {{item.value}}
          </template>
        </v-sparkline>
      </v-sheet>
      <v-row class="p-0 mx-1 mt-1 mb-0">
        <v-col cols="12" class="m-0 p-0">
          <div class="my-1 mx-0 flexb falign-center" style="justify-content: space-between;">
            <v-card class="w30 p-0 text-center" v-for="(label,index) in distribuicaoLabels" :key="label">
              <v-tooltip bottom open-delay="1200">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <span v-if="index<3" class="fs-7pt f-roboto fw-600">{{label}}</span>
                    <span v-if="index>=3" class="fs-7pt f-roboto fw-600">{{label}}</span>
                  </span>
                </template>
                <p class="p-0 m-0 f-roboto fs-8pt" v-for="(uc,idx) in distribuicaoQuestoes[index]" :key="idx" v-if="idx < 12">
                  {{uc[Object.keys(uc)[0]]}}
                </p>
                <p class="p-0 m-0 f-roboto fw-800 fs-8pt" v-if="distribuicaoQuestoes[index].length >= 12">
                  mais {{distribuicaoQuestoes[index].length - 12}} não listadas
                </p>
              </v-tooltip>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <div class="ms-0 mt-3 mb-1 text-right f-raleway fw-500 fs-12pt" v-cHex="hexShades(cor.active.B,0.5)">
        <v-btn @click="$emit('cardClick')" class="text-nonecase f-sanspro fw-400 fs-8pt" v-c:I outlined x-small>
          ver detalhes
        </v-btn>
      </div>
    </div>
    <v-overlay absolute :value="loading">
      <div class="text-center">
        <v-progress-circular color="white" indeterminate size="64"></v-progress-circular>
      </div>
      <div class="text-center mb-4" style="margin-top: 20px;">
        <img width="180px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
      </div>
    </v-overlay>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import moment from "moment";

export default {
  name: "cardmetasquestoes",
  components: {},
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      pessoasOnline: [],
      pessoasTotal: 0,
      distribuicao: [],
      distribuicaoMetas: [],
      distribuicaoQuestoes: [],
      distribuicaoLabels: [],
      ucsOfertadas: {},
      questoes: {},
      metasIndicadas: {},
      metasPorUc: {},
      questoesPorUc: {},
      ucsSemQuestoes: {},
      qtdeQuestoes: 0,
      qtdeQuestoesTotal: 0,
      qtdeQuestoesSemUso: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;

    rdb.ref('/curriculo/ucs').orderByChild("oferta2021S2").equalTo(true).on('value',function(snapshot) {
      self.ucsOfertadas = snapshot.val();
      console.log("self.ucsOfertadas",self.ucsOfertadas);

      rdb.ref('/curriculo/avaliacao/2021S2/metasIndicadasFinal').on('value',function(snapshot) {
        self.metasIndicadas = snapshot.val();
        console.log("self.metasIndicadas",self.metasIndicadas);
        self.questoesPorUc = {};
        self.qtdeQuestoes = 0;

        var qtde = 0;
        for(var key in self.metasIndicadas) {
          var meta = self.metasIndicadas[key];
          if(meta.metaCodigo != undefined && meta.metaCodigo != "") {
            qtde++;
          }
        }
        var qtde2 = 0;
        for(var key in self.metasIndicadas) {
          var meta = self.metasIndicadas[key];
          self.qtdeQuestoesTotal = 0;
          self.qtdeQuestoesSemUso = 0;
          self.qtdeQuestoes = 0;
          if(meta.metaCodigo != undefined && meta.metaCodigo != "") {
            rdb.ref('/curriculo/questoesValidas').orderByChild("metaCodigo").equalTo(meta.metaCodigo).once('value',function(snapshot) {
              var questoes = snapshot.val();
              if(questoes != null) {
                for(var keyQ in questoes) {
                  self.qtdeQuestoesTotal++;
                  if(self.ucsOfertadas[questoes[keyQ].ucID] == undefined) {
                    self.qtdeQuestoesSemUso++;
                  } else {
                    if(self.questoesPorUc[questoes[keyQ].ucID] == undefined) {
                      self.questoesPorUc[questoes[keyQ].ucID] = {};
                    }
                    self.questoesPorUc[questoes[keyQ].ucID][keyQ] = questoes[keyQ];
                    self.qtdeQuestoes++;
                  }
                }
              }
              qtde2++;
              if(qtde == qtde2) {
                self.build();
              }
            });
          }
        }
        console.log("self.questoesPorUc",self.questoesPorUc);
        console.log("qtde self.questoesPorUc",Object.keys(self.questoesPorUc).length);
      });


    });


  },
  methods:{

    getQuestoes() {
    },

    build() {
      var self = this;
      console.log("build");
      console.log("self.ucsOfertadas",self.ucsOfertadas);
      console.log("qtde self.ucsOfertadas",Object.keys(self.ucsOfertadas).length);
      console.log("self.questoesPorUc",self.questoesPorUc);
      console.log("qtde self.questoesPorUc",Object.keys(self.questoesPorUc).length);
      self.loading = true;

      var ucsNE = [];

      self.distribuicao = [ 0, 0, 0, 0, 0, 0, 0 ];
      self.distribuicaoLabels = [ "0", "1-2", "3-10", "11-20", "21-30", "31-40", "+40"];
      self.distribuicaoQuestoes = [ [], [], [], [], [], [], [] ];

      self.ucsSemQuestoes = {};
      for(var key in self.ucsOfertadas) {
        if(self.questoesPorUc[key] == undefined) {
          self.ucsSemQuestoes[key] = self.ucsOfertadas[key];
          self.distribuicaoQuestoes[0].push(self.ucsOfertadas[key].nome);
        }
      }
      console.log("self.ucsSemQuestoes",self.ucsSemQuestoes);
      console.log("qtde self.ucsSemQuestoes",Object.keys(self.ucsSemQuestoes).length);

      self.distribuicao[0] = Object.keys(self.ucsSemQuestoes).length;

      for(var key in self.questoesPorUc) {
        //var ucID = self.metasIndicadas[self.questoesPorUc[key].metaID].ucID;
        var uc = self.ucsOfertadas[key];
        var ucNome = "";
        if(uc != undefined) {
          ucNome = uc.nome;
        } else {
          console.log("uc não encontrada",self.questoesPorUc[key]);
        }
        var qtdeQuestoes = Object.keys(self.questoesPorUc[key]).length;
        if(qtdeQuestoes <= 2) {
          self.distribuicao[1]++;
          self.distribuicaoQuestoes[1].push();
        }
        if(qtdeQuestoes > 2 && qtdeQuestoes <= 10) {
          self.distribuicao[2]++;
          self.distribuicaoQuestoes[2].push(ucNome);
        }
        if(qtdeQuestoes > 10 && qtdeQuestoes <= 20) {
          self.distribuicao[3]++;
          self.distribuicaoQuestoes[3].push(ucNome);
        }
        if(qtdeQuestoes > 20 && qtdeQuestoes <= 30) {
          self.distribuicao[4]++;
          self.distribuicaoQuestoes[4].push(ucNome);
        }
        if(qtdeQuestoes > 30 && qtdeQuestoes <= 40) {
          self.distribuicao[5]++;
          self.distribuicaoQuestoes[5].push(ucNome);
        }
        if(qtdeQuestoes > 40) {
          self.distribuicao[6]++;
          self.distribuicaoQuestoes[6].push(ucNome);
        }
      }
      console.log("self.distribuicao",self.distribuicao);
      console.log("self.distribuicaoQuestoes",self.distribuicaoQuestoes);
      self.loading = false;
    }
  }
}
</script>

<style scoped>
</style>
